import React,{useState} from "react";

const MyListComponent=()=>{
    const [students,setStudents]=useState([
        {id:1, firstName:'Jim', age:21, country:'USA'},
        {id:2, firstName:'Maria', age:23, country:'canada'},
        {id:3, firstName:'Kevin', age:17, country:'India'}    

    ]);
    const [newStudent, setNewStudent]= useState(
        {firstName:'', age:'', country:''}
    );

    const addStudent= ()=>{
        if(newStudent.firstName && newStudent.age && newStudent.country){
            setStudents([
                ...students,
                {id:students.length+1, ...newStudent}
            ]);
            setNewStudent({firstName:'',age:'',country:''});
        }
    }
    const removeStudent= (id)=>{
        setStudents(students.filter(student=>student.id!==id));
    }

    return (
        <div style={{marginTop:'20px'}}>
            <h2>Student List</h2>
            <ul>
                {students.map((student)=>(
                    <li >
                        {student.firstName} (Age: {student.age}, Country:{student.country})
                        <button onClick={()=>removeStudent(student.id)} style={{marginLeft:'10px'}}>Remove</button>

                    </li>

                ))}
            </ul>
            <div style={{marginTop:'20px'}}>
                <input type="text" placeholder="FirstName" value={newStudent.firstName} onChange={(e)=>setNewStudent({...newStudent,firstName:e.target.value})}/>
                <input
                    type="number"
                    placeholder="Age"
                    value={newStudent.age}
                    onChange={(e)=>setNewStudent({...newStudent,age:e.target.value})}
                />
                <input
                    type="text"
                    placeholder="Country"
                    value={newStudent.country}
                    onChange={(e)=>setNewStudent({...newStudent,country:e.target.value})}
                />
                <button onClick={addStudent}>Add Student</button>

            </div>
            <div style={{marginTop:'20px'}}></div>

        </div>
    )

}

export default MyListComponent;