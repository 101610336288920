import MyImageComponent from './MyImageComponent'
import MyListComponent from './MyListComponent'
function App() {
  return (
    <div className="App">
      <h1> Lists, Images and Styles</h1>
      <MyImageComponent/>
      <MyListComponent/>
    </div>
  );
}

export default App;
