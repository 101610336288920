import SampleImage from './images/sample.jpg';
function MyImageComponent(){
    return (
        <div style={{textAlign:'center', margin:'20px'}}>
            <h2>Sample Image</h2>
            <img src={SampleImage} alt="Sample" style={{width:'150px', height:'150px', borderRadius:'8px', border:'2px solid #ddd'}}/>
        </div>
    )
}

export default MyImageComponent;